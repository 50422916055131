<template>
  <v-container>
    <v-overlay v-if="loadingGral" :z-index="zIndex" :value="overlay">
      <v-progress-circular indeterminate :size="70" :width="7" color="primary"></v-progress-circular>
    </v-overlay>
    <v-row>
      <v-breadcrumbs :items="bread" large></v-breadcrumbs>
    </v-row>
    <v-row>
      <v-col lg="12">
        <h1>{{ nombre }}</h1>
        <h3>Operatoria</h3>
      </v-col>
    </v-row>
    <v-row v-if="precalificar">
      <v-col lg="12">
        <Wizard :token="this.token" :lineaCreditoClienteId="newLccId ? lccCreada : this.$route.params.id"> </Wizard>
      </v-col>
    </v-row>
    <v-row v-if="!precalificar && mostrarInformacion === 2">
      <v-col xl="12" lg="12" md="12" sm="12" align="center">
        <v-btn color="info accent-4" block elevation="2" @click="ocultarInformacion()"
          ><v-icon color="write darken-2"> mdi-minus </v-icon>Ocultar información de la unidad de gestión</v-btn
        >
      </v-col>
    </v-row>
    <v-alert
      v-if="!requisitos && !requisitosListos && showAlertRequisitos"
      border="bottom"
      class="mt-5"
      colored-border
      type="warning"
      elevation="2"
    >
      No es posible precalificar para la linea de credito seleccionada porque aun no presenta requisitos documentales.
    </v-alert>
    <v-row v-if="!precalificar">
      <v-col lg="12" sm="12" md="12" xl="12"> &nbsp; </v-col>
    </v-row>
    <div v-if="!precalificar">
      <v-card>
        <v-row v-if="LineaSeleccionadaOk">
          <v-col xl="6" lg="6" md="6" sm="12">
            <v-card-title> Descripción: </v-card-title>
            <v-card-text>{{ this.descripcionLinea }}</v-card-text>
            <v-card-title> Sector Productivo: </v-card-title>
            <v-card-text>{{ this.sectorProductivo }}</v-card-text>
            <v-card-title> Reglamento: </v-card-title>
            <v-card-text>
              <v-row v-if="this.tasaMostrarOK">
                <v-col>
                  <p><b>Tasa:</b></p>
                  {{ formatoPorcentaje(this.tasa || this.tasaMostrar) }}%
                </v-col>
              </v-row>
              <v-row v-if="this.plazoMaximoMostrarOK">
                <v-col>
                  <p><b>Plazo máximo:</b></p>
                  <div v-if="this.plazoMaximoMostrar != 1">{{ this.plazoMaximoMostrar }} meses</div>
                  <div v-else>{{ this.plazoMaximoMostrar }} mes</div>
                </v-col>
              </v-row>
              <v-row v-if="tipoServicioId === 20">
                <v-col>
                  <p><b>Tope Reintegro:</b></p>
                  <p v-if="servParametros.length > 0">
                    $
                    {{
                      servParametros.filter((p) => p.clave == 'tope_reintegro')[0].valor || 'No esta configurado como '
                    }}
                  </p>
                </v-col>
              </v-row>
              <v-row v-if="this.basesMostrarOK">
                <v-col>
                  <p><b>Bases:</b></p>
                  <v-btn v-on:click="verDoc(basesMostrar)" color="success">
                    <v-icon>mdi-magnify</v-icon> Ver documento
                  </v-btn>
                </v-col>
              </v-row>
              <v-row v-if="this.montoMaximoMostrarOK">
                <v-col>
                  <p><b>Monto máximo:</b></p>
                  $ {{ formatoMoneda(this.montoMaximoMostrar) }}
                </v-col>
              </v-row>
            </v-card-text>
          </v-col>
          <v-col xl="5" lg="5" md="5" sm="12">
            <div v-if="tipoServicioId === 13 && lineaSeleccionada !== 71">
              <v-form v-if="parametros" id="myform" ref="form" lazy-validation>
                <v-text-field
                  v-model="monto"
                  :rules="montoRules"
                  label="Monto"
                  type="number"
                  :readonly="this.gestionarCredito"
                  required
                ></v-text-field>
                <v-text-field
                  v-model="periodo"
                  :rules="periodoRules"
                  label="Período de gracia"
                  type="number"
                  :readonly="this.gestionarCredito"
                  required
                ></v-text-field>
                <v-text-field
                  v-model="plazo"
                  :rules="plazoRules"
                  label="Plazo de crédito"
                  type="number"
                  :readonly="this.gestionarCredito"
                  required
                ></v-text-field>
                <v-select
                  label="Pagos"
                  v-model="pagos"
                  :rules="[(v) => !!v || 'Se necesita un pago']"
                  :items="[
                    { value: 1, text: 'Mensual' },
                    { value: 2, text: 'Bimestral' },
                    { value: 3, text: 'Trimestral' },
                    { value: 6, text: 'Semestral' },
                    { value: 12, text: 'Anual' },
                  ]"
                  required
                  :readonly="this.gestionarCredito"
                >
                </v-select>
                <v-text-field
                  v-model="cbu"
                  :loading="loadingCBU"
                  :success="cbuSuccess"
                  :error="cbuError"
                  :messages="cbuMsg"
                  label="CBU"
                  type="number"
                ></v-text-field>
                <v-text-field v-if="operatoria.id === 44" v-model="fiador_nombre" label="Nombre fiador"></v-text-field>
                <v-text-field
                  v-if="operatoria.id === 44"
                  v-model="apellido_fiador"
                  label="Apellido fiador"
                ></v-text-field>
                <v-text-field
                  v-if="operatoria.id === 44"
                  :disabled="checkbox"
                  v-model="cuit_fiador"
                  :counter="11"
                  @keypress="valCUITCUIL(cuit_fiador)"
                  v-on:blur="valCUITCUILMin(cuit_fiador)"
                  ref="cuilcuitben"
                  clearable
                  label="CUIT fiador"
                  type="number"
                  hint="Ingrese el CUIL/CUIT sin guiones o espacios."
                  required
                ></v-text-field>
                <v-text-field
                  v-if="operatoria.id === 44"
                  v-model="domicilio_fiador"
                  label="Domicilio fiador"
                ></v-text-field>
                <v-menu
                  ref="menu"
                  v-model="menu"
                  :close-on-content-click="false"
                  :return-value.sync="fecha"
                  transition="scale-transition"
                  offset-y
                  :readonly="this.gestionarCredito"
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="formatDate"
                      label="Fecha de inicio"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="fecha" no-title scrollable>
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="menu = false"> Cancelar </v-btn>
                    <v-btn text color="primary" @click="$refs.menu.save(fecha)"> Aceptar </v-btn>
                  </v-date-picker>
                </v-menu>
                <div v-if="!datosConfirmados">
                  <v-checkbox
                    v-if="!this.parametrosCliente && !this.gestionarCredito"
                    v-model="checkbox"
                    :disabled="!fecha || !cbu || !pagos || !plazo || !monto || !montoValido"
                    :rules="[(v) => !!v || 'Debe aceptar para continuar!']"
                    label="Acepto los valores"
                    required
                  ></v-checkbox>
                  <v-btn
                    v-if="!this.parametrosCliente && !this.gestionarCredito"
                    :disabled="!checkbox || !montoValido"
                    color="success"
                    class="mr-4"
                    @click="btnConfirmarValores"
                  >
                    Confirmar Valores
                  </v-btn>
                </div>
                <v-alert v-if="datosConfirmados" border="left" color="green" dense outlined text type="success"
                  >Los parametros han sido confirmados</v-alert
                >
              </v-form>
              <v-card v-else>
                <v-card-title>Datos Requisitos: </v-card-title>
                <v-card-text>No se encuentra habilitado por falta de parámetros </v-card-text>
              </v-card>
            </div>
            <ModalParametrosClienteActiva
              v-if="
                tipoServicioId === 20 &&
                this.servParametros.length > 1 &&
                !isCulturalyTurismo &&
                typeof listaParametrosOperatoriaCliente !== 'undefined'
              "
              :listaParametrosOperatoriaCliente="listaParametrosOperatoriaCliente"
              :parametrosOperatoria="servParametros"
              @enviarParametros="getDatosParametros"
              :parametros="datosParametros"
              :lineaSeleccionada="lineaSeleccionada"
            ></ModalParametrosClienteActiva>
            <ModalParametrosClienteActivaSub
              v-if="
                tipoServicioId === 20 &&
                this.servParametros &&
                isCulturalyTurismo &&
                typeof listaParametrosOperatoriaCliente !== 'undefined'
              "
              :listaParametrosOperatoriaCliente="listaParametrosOperatoriaCliente"
              :parametrosOperatoria="servParametros"
              @enviarParametros="getDatosParametros"
              :parametros="datosParametros"
              :lineaSeleccionada="lineaSeleccionada"
            ></ModalParametrosClienteActivaSub>
            <ModalParametrosClientesEnlace
              v-if="tipoServicioId === 23"
              :listaParametrosOperatoriaCliente="listaParametrosOperatoriaCliente"
              @enviarParametros="getDatosParametros"
              :parametros="datosParametros"
              :lineaSeleccionada="lineaSeleccionada"
            ></ModalParametrosClientesEnlace>
            <ModalParametrosClientesAnr
              v-if="tipoServicioId === 19"
              :listaParametrosOperatoriaCliente="listaParametrosOperatoriaCliente"
              @enviarParametros="getDatosParametros"
              :parametros="datosParametros"
              :lineaSeleccionada="lineaSeleccionada"
              :parametrosOperatoria="servParametros"
            ></ModalParametrosClientesAnr>
          </v-col>
          <v-col> </v-col>
        </v-row>
      </v-card>
    </div>
    <v-row v-if="!precalificar && lineasCredito.length > 0">
      <v-col lg="12" sm="12" md="12" xl="12"> &nbsp; </v-col>
    </v-row>
    <v-row v-if="!precalificar">
      <v-col lg="12" sm="12" md="12" xl="12">
        <v-card :loading="loadingGral" v-if="requisitosArray" style="margin-top: 15px">
          <v-card-title> Requisitos: </v-card-title>
          <v-card-text>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">Nombre</th>
                    <th class="text-left">Descripción</th>
                    <th class="text-left">Etapa</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="f in requisitosArray" :key="f.id">
                    <td>{{ f.nombre }}</td>
                    <td>{{ f.descripcion }}</td>
                    <td>{{ f.etapa.nombre }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-if="!precalificar && this.requisitos">
      <v-col lg="12" sm="12" md="12" xl="12"> &nbsp; </v-col>
    </v-row>
    <v-row class="d-flex d-sm-flex d-md-flex">
      <v-col cols="12" v-if="!precalificar && !this.gestionarCredito">
        <Modal
          v-if="tipoServicioId === 13 && lineaSeleccionada !== 71"
          :params="this.servParametros"
          :nombre="this.nombre"
        ></Modal>
      </v-col>
      <v-col cols="12" v-if="(!precalificar && !this.gestionarCredito) || tipoServicioId !== 13">
        <v-btn
          :disabled="lineaSeleccionada === 71 ? false : !requisitosListos || !parametrosCliente || clickPrecalificar"
          color="success accent-4"
          block
          elevation="2"
          @click="insertarLineaCredito"
          >Precalificar
        </v-btn>
      </v-col>
      <v-col v-if="!precalificar && gestionarCredito && tipoServicioNombre === 'Financiamiento'">
        <v-btn :disabled="!requisitosListos" color="info accent-4" block elevation="2" @click="gestion"
          >Gestionar</v-btn
        >
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { APP_SIMULADOR_URL, API_URL } from '@/common/config';
import moment from 'moment';
import Wizard from '../components/Wizard.vue';
import Modal from '../components/Modal.vue';
import ModalParametrosClienteActiva from '../components/ModalParametrosClienteActiva.vue';
import ModalParametrosClientesEnlace from '../components/ModalParametrosClientesEnlace.vue';
import ModalParametrosClientesAnr from '../components/ModalParametrosClientesAnr.vue';
import ModalParametrosClienteActivaSub from '../components/ModalParametrosClienteActivaSub.vue';

const axios = require('axios');

/* eslint-disable prefer-template */
export default {
  name: 'Fideicomiso',
  components: {
    Wizard,
    Modal,
    ModalParametrosClienteActiva,
    ModalParametrosClienteActivaSub,
    ModalParametrosClientesEnlace,
    ModalParametrosClientesAnr,
  },
  data: () => ({
    datosConfirmados: false,
    loadingLinea: true,
    loadingGral: false,
    zIndex: 1,
    overlay: true,
    clickPrecalificar: false,
    listaParametrosOperatoriaCliente: [],
    mostrarInformacion: 0,
    newLccId: false,
    nombre: '',
    descripcion: '',
    imagen: '',
    operatoriaId: '',
    sectorProductivo: '',
    tipoServicioId: null,
    requisitos: false,
    requisitosListos: false,
    requisitosArray: [],
    showAlertRequisitos: false,
    token: localStorage.getItem('token'),
    precalificar: false,
    lineasCredito: [],
    lineaSeleccionada: '',
    lineaCreditoCliente: '',
    LineaSeleccionadaOk: false,
    descripcionLinea: '',
    imagenLinea: '',
    sectorProductivoLinea: '',
    tipoCredito: 0,
    tipoServicioNombre: '',
    gestionarCredito: false,
    servParametros: [],
    parametros: false,
    menu: '',
    operId: 0,
    fiador_nombre: '',
    apellido_fiador: '',
    cuit_fiador: '',
    domicilio_fiador: '',
    parametrosCliente: false,
    datosParametros: [],
    clienteParametros: [],
    lccCreada: false,
    isCulturalyTurismo: false,
    bread: [
      {
        text: 'Mendoza Fiduciaria',
        disabled: false,
        href: '/',
      },
      {
        text: 'Fideicomiso',
        disabled: false,
        href: '/catalogo',
      },
    ],
    tipoCred: 'fideicomiso',
    monto: '',
    periodo: '',
    plazo: '',
    pagos: '',
    tasa: '',
    cbu: '',
    loadingCBU: false,
    cbuError: false,
    cbuSuccess: false,
    cbuMsg: '',
    tasaMostrar: '',
    tasaMostrarOK: false,
    basesMostrar: '',
    basesMostrarOK: false,
    plazoMaximoMostrar: '',
    plazoMaximoMostrarOK: false,
    montoMaximoMostrar: '',
    montoMaximoMostrarOK: false,
    checkbox: false,
    fecha: new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
  }),
  computed: {
    formatDate() {
      return this.fecha ? moment(this.fecha).format('DD-MM-YYYY') : '';
    },
    periodoRules() {
      return [
        (v) => !!v || 'Falta el periodo',
        (v) =>
          (v >= 0 && v <= parseInt(this.servParametros.find((e) => e.clave === 'periodo_gracia_maximo').valor, 10)) ||
          `El período debe gracia máximo ${this.servParametros.find((e) => e.clave === 'periodo_gracia_maximo').valor}`,
      ];
    },
    montoRules() {
      return [
        (v) => !!v || 'Falta monto de la operación',
        (v) =>
          (v >= parseInt(this.servParametros.find((e) => e.clave === 'monto_minimo').valor, 10) &&
            v <= parseInt(this.servParametros.find((e) => e.clave === 'monto_maximo').valor, 10)) ||
          `El monto debe estar entre ${this.servParametros.find((e) => e.clave === 'monto_minimo').valor} y ${
            this.servParametros.find((e) => e.clave === 'monto_maximo').valor
          }`,
      ];
    },
    montoValido() {
      const montoMinimo = parseInt(this.servParametros.find((e) => e.clave === 'monto_minimo').valor, 10);
      const montoMaximo = parseInt(this.servParametros.find((e) => e.clave === 'monto_maximo').valor, 10);
      return this.monto >= montoMinimo && this.monto <= montoMaximo;
    },
    plazoRules() {
      return [
        (v) => !!v || 'Falta valor plazo',
        (v) =>
          (v >= parseInt(this.servParametros.find((e) => e.clave === 'plazo_credito_minimo').valor, 10) &&
            v <= parseInt(this.servParametros.find((e) => e.clave === 'plazo_credito_maximo').valor, 10)) ||
          `El plazo debe estar entre ${this.servParametros.find((e) => e.clave === 'plazo_credito_minimo').valor} y ${
            this.servParametros.find((e) => e.clave === 'plazo_credito_maximo').valor
          }`,
      ];
    },
  },
  watch: {
    async cbu(v) {
      if (v.length === 22) {
        console.log('Validando CBU...');
        await this.validarCBU(v);
      }
    },
  },
  methods: {
    valCUITCUIL(v) {
      if (v.length > 10) {
        this.$swal.fire('CUIL/CUIT del Beneficiario', 'Debe ingresar solo CUIT/CUIL sin guiones y/o espacios', 'error');
        this.cuit_fiador = '';
      }
    },
    valCUITCUILMin(v) {
      if (v.length < 10) {
        this.$swal.fire('CUIL/CUIT del Beneficiario', 'Debe ingresar solo CUIT/CUIL sin guiones y/o espacios', 'error');
        this.cuit_fiador = '';
      }
    },
    async validarCBU(clavebancariaunica) {
      this.loadingCBU = true;
      const url = `${API_URL}api/validar/cbu`;
      const header = {
        headers: {
          'Content-Type': 'application/json',
          token: localStorage.getItem('token'),
        },
      };
      const data = {
        cbu: clavebancariaunica,
      };
      axios.post(url, data, header).then((result) => {
        let msg = '';
        if (result.data.valid) {
          msg = 'CBU válido';
          this.cbuSuccess = true;
          this.cbuError = false;
        } else {
          msg = 'CBU no válido';
          this.cbuError = true;
          this.cbuSuccess = false;
        }
        this.loadingCBU = false;
        this.cbuMsg = msg;
      });
    },
    validarCulturalyTurismo() {
      let r = false;
      const subtipo = this.servParametros.find((e) => e.clave === 'subtipo_mza_activa');
      if (subtipo) {
        if (parseInt(subtipo.valor, 10) === 3) {
          r = true;
        }
      } else {
        r = false;
      }
      this.isCulturalyTurismo = r;
    },
    async getTasaVariable() {
      const query = {
        url: `${APP_SIMULADOR_URL}tnav`,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        data: {
          url: 'https://www.bna.com.ar/Home/InformacionAlUsuarioFinanciero',
          caracter: 8,
        },
      };
      const { data: tasaVariable } = await axios(query);
      return tasaVariable.toFixed(2);
    },
    getDatosParametros(datosParametros) {
      this.datosParametros = datosParametros;
      this.requisitosListos = true;
      this.parametrosCliente = true;
    },
    mostraInformacion() {
      this.mostrarInformacion = 2;
    },
    ocultarInformacion() {
      this.mostrarInformacion = 1;
    },
    async getListaParametrosOperatoria(tipoServicioId, destino) {
      const url = `${API_URL}api/tipoParametros/${tipoServicioId}/${destino}`;
      const header = {
        headers: {
          'Content-Type': 'application/json',
          token: this.token,
        },
      };
      await axios.get(url, header).then((response) => {
        if (typeof response.data.listaParametros !== 'undefined') {
          this.listaParametrosOperatoriaCliente = response.data.listaParametros.map((el) => ({ ...el, model: '' }));
        } else {
          this.listaParametrosOperatoriaCliente = response.data.listaParametros;
        }
      });
    },
    async obtenerReglamento(lineaCredito) {
      this.tasaMostrarOK = false;
      this.basesMostrarOK = false;
      this.plazoMaximoMostrarOK = false;
      this.montoMaximoMostrarOK = false;

      if (this.basesMostrar !== '' && this.basesMostrar !== null) {
        this.basesMostrarOK = true;
      }
      await axios
        .get(`${API_URL}api/tipoParametros/operatoria/${lineaCredito}`, {
          headers: {
            token: localStorage.getItem('token'),
          },
        })
        .then((response) => {
          const valoresParametros = response.data.servicioParametro;
          const nombreParametros = response.data.tipoParametro;
          nombreParametros.forEach((parametros) => {
            valoresParametros.forEach((valores) => {
              if (parametros.id === valores.tipo_parametro_id) {
                switch (parametros.clave) {
                  case 'monto_maximo':
                    this.montoMaximoMostrar = valores.valor;
                    if (this.montoMaximoMostrar !== '') {
                      this.montoMaximoMostrarOK = true;
                    }
                    break;
                  case 'plazo_credito_maximo':
                    this.plazoMaximoMostrar = valores.valor;
                    if (this.plazoMaximoMostrar !== '') {
                      this.plazoMaximoMostrarOK = true;
                    }
                    break;
                  case 'tasa':
                    this.tasaMostrar = valores.valor;
                    if (this.tasaMostrar !== '') {
                      this.tasaMostrarOK = true;
                    }
                    break;
                  default:
                    // otra opción no definida
                    break;
                }
              }
            });
          });
        });
    },
    verDoc(url) {
      const docUrl = `${API_URL}bases/${url}`;
      window.open(docUrl);
    },
    async obtenerRequisitosLineaCredito(id) {
      await axios
        .get(`${API_URL}api/documentacionClientes/servicio/${id}/0`, {
          headers: {
            token: localStorage.getItem('token'),
          },
        })
        .then((response) => {
          if (response.data.status === 'error') {
            this.requisitos = false;
            this.showAlertRequisitos = true;
          } else {
            // this.requisitosArray = response.data.listaDocumentosCliente;
            this.requisitos = true;
            this.requisitosListos = true;
            this.verificarBtnPrecalificar();
          }
        });
    },
    obtenerRequisitosFideicomiso() {
      axios
        .get(`${API_URL}api/documentacionClientes/unidadGestion/${this.$route.params.id}`, {
          headers: {
            token: localStorage.getItem('token'),
          },
        })
        .then((response) => {
          if (response.data.status === 'error') {
            console.log('Error: faltan los requisitos del fideicomiso'); // BORRAR
            this.requisitos = false;
          } else {
            // this.requisitosArray = response.data.listaDocumentosCliente;
            this.requisitos = true;
            this.verificarBtnPrecalificar();
          }
        });
    },
    async obtenerServicioParametro() {
      // Obtengo los parametros de un servicio
      await axios
        .get(`${API_URL}api/servicioParametros/operatoria/${this.lineaSeleccionada}`, {
          headers: {
            token: localStorage.getItem('token'),
          },
        })
        .then((response) => {
          if (typeof response.data.parametros !== 'undefined') {
            this.validarCulturalyTurismo();
          }
        });
    },
    verificarBtnPrecalificar() {
      const url = `${API_URL}api/servicio/clientes/${this.lineaSeleccionada}/${localStorage.getItem('cliente_id')}`;
      axios
        .get(url, {
          headers: {
            token: localStorage.getItem('token'),
          },
        })
        .then((response) => {
          if (response.data.message === 'No hay lineaCreditoCliente asociada') {
            this.requisitosListos = true;
          }
          if (response.data.lineaCreditoCliente) {
            this.requisitosListos = true;
            this.gestionarCredito = true;
            this.lineaCreditoCliente = response.data.lineaCreditoCliente;
          }
          if (this.gestionarCredito) {
            this.obtenerServicioParametroCliente();
          }
        });
    },
    mostrarWizard() {
      this.precalificar = true;
      this.loadingGral = true;
    },
    gestion() {
      const url = `/creditoView/${this.lineaCreditoCliente.id}`;
      this.$router.push(url);
      this.mostrarWizard();
    },
    async controlarLinea() {
      try {
        const response = await axios.post(`${API_URL}api/lineaCreditoClientes/linea`, {
          token: this.token,
          lineaCredito_id: this.lineaSeleccionada,
          cliente_id: localStorage.getItem('cliente_id'),
        });

        if (response.data.status === 'success') {
          return response.data.result;
        }
        return false;
      } catch (error) {
        console.error('Error in controlarLinea:', error);
        return false;
      }
    },
    async insertarLineaCredito() {
      this.loadingGral = true;
      this.clickPrecalificar = true;
      const ok = await this.controlarLinea();
      if (!ok) {
        this.$swal.fire({
          title: 'Alerta',
          text: 'Usted no puede "Precalificar" para esta linea, consulte con Administración',
          icon: 'warning',
          confirmButtonColor: '#007bff',
        });
        this.loadingGral = false;
        return;
      }
      axios
        .post(`${API_URL}api/lineaCreditoClientes`, {
          token: this.token,
          lineaCredito_id: this.lineaSeleccionada,
          cliente_id: localStorage.getItem('cliente_id'),
          estado: 'PRECALIFICACION',
        })
        .then((response) => {
          if (response.data.status === 'success') {
            this.guardarClienteParametro(response.data.lineaCreditoCliente.id);

            this.lccCreada = response.data.lineaCreditoCliente.id;
            // this.tipoServicioNombre = 'Financiamiento';
            this.newLccId = true;
            this.guardarClienteTrilay(this.lccCreada);
            this.mostrarWizard();
          }
          this.loadingGral = false;
        })
        .catch((e) => {
          throw new Error(e);
        });
    },
    async guardarClienteTrilay(lccId) {
      const url = `${API_URL}api/clientes/trilay/insertar`;
      const header = {
        headers: {
          token: localStorage.getItem('token'),
        },
      };
      const parametros = this.datosParametros;
      const data = { lcc_id: lccId, parametros };
      await axios
        .post(url, data, header)
        .then((r) => {
          console.log(r);
        })
        .catch((e) => {
          console.log(e);
        });
    },
    btnConfirmarValores() {
      //  Aca se confirman los valores que ingresamos monto, cbu, etc
      const data = this.datosParametros;
      this.datosParametros = [
        { id: data.find((e) => e.clave === 'monto').id, name: 'monto', value: this.monto }, //
        { id: data.find((e) => e.clave === 'periodo_gracia').id, name: 'periodo_gracia', value: this.periodo }, //
        { id: data.find((e) => e.clave === 'plazo_credito').id, name: 'plazo_credito', value: this.plazo }, //
        { id: data.find((e) => e.clave === 'periodicidad_pagos').id, name: 'periodicidad_pagos', value: this.pagos }, //
        { id: data.find((e) => e.clave === 'fecha_inicio').id, name: 'fecha_inicio', value: this.formatDate }, //
        { id: data.find((e) => e.clave === 'tasa_legajo').id, name: 'tasa_legajo', value: this.tasa }, //
        { id: data.find((e) => e.clave === 'cbu_financiamiento').id, name: 'cbu_financiamiento', value: this.cbu }, // +
      ];
      if (this.operatoria.id === 44) {
        this.datosParametros.push({
          id: data.find((e) => e.clave === 'fiador_nombre').id,
          name: 'fiador_nombre',
          value: this.fiador_nombre,
        });
        this.datosParametros.push({
          id: data.find((e) => e.clave === 'apellido_fiador').id,
          name: 'apellido_fiador',
          value: this.apellido_fiador,
        });
        this.datosParametros.push({
          id: data.find((e) => e.clave === 'cuit_fiador').id,
          name: 'cuit_fiador',
          value: this.cuit_fiador,
        });
        this.datosParametros.push({
          id: data.find((e) => e.clave === 'domicilio_fiador').id,
          name: 'domicilio_fiador',
          value: this.domicilio_fiador,
        });
      }
      this.parametrosCliente = true;
      this.datosConfirmados = true;
    },
    guardarClienteParametro(lineaccId) {
      // Anticipo de Cosecha, Acarreo y Elaboración de Uva =71
      if (this.lineaSeleccionada === 71) {
        const data = this.datosParametros;
        this.datosParametros = [
          { id: data.find((e) => e.clave === 'monto').id, name: 'monto', value: 0 },
          {
            id: data.find((e) => e.clave === 'periodo_gracia').id,
            name: 'periodo_gracia',
            value: 'Anticipo de Cosecha',
          },
          { id: data.find((e) => e.clave === 'plazo_credito').id, name: 'plazo_credito', value: 'Anticipo de Cosecha' },
          {
            id: data.find((e) => e.clave === 'periodicidad_pagos').id,
            name: 'periodicidad_pagos',
            value: 'Anticipo de Cosecha',
          },
          { id: data.find((e) => e.clave === 'fecha_inicio').id, name: 'fecha_inicio', value: 'Anticipo de Cosecha' },
          { id: data.find((e) => e.clave === 'tasa_legajo').id, name: 'tasa_legajo', value: 'Anticipo de Cosecha' },
          {
            id: data.find((e) => e.clave === 'cbu_financiamiento').id,
            name: 'cbu_financiamiento',
            value: 'Anticipo de Cosecha',
          },
        ];
      }
      axios
        .post(`${API_URL}api/clienteParametros/nuevo/`, {
          token: this.token,
          lineaCredito_id: lineaccId,
          datos: this.datosParametros,
        })
        .then((response) => {
          if (response.data.status === 'success') {
            this.parametrosCliente = true;
          } else {
            this.$swal.fire(response.data.message, 'error');
          }
        });
    },
    obtenerServicioParametroCliente() {
      // Get Informacion del formulario parametro
      axios
        .post(`${API_URL}api/clienteParametros/cliente`, {
          token: this.token,
          lineaCredito_id: this.$route.params.servicioid,
          cliente_id: localStorage.getItem('cliente_id'),
        })
        .then((response) => {
          this.clienteParametros = response.data.ClienteParametro.map((f) => ({
            id: f.tipo_parametro_id.id,
            clave: f.tipo_parametro_id.clave,
            valor: f.valor,
          }));
          this.monto = parseInt(this.clienteParametros.find((e) => e.clave === 'monto').valor, 10);
          this.periodo = parseInt(this.clienteParametros.find((e) => e.clave === 'periodo_gracia').valor, 10);
          this.plazo = parseInt(this.clienteParametros.find((e) => e.clave === 'plazo_credito').valor, 10);
          this.pagos = parseInt(this.clienteParametros.find((e) => e.clave === 'periodicidad_pagos').valor, 10);
          this.fecha = this.clienteParametros.find((e) => e.clave === 'fecha_inicio');
        });
    },
    async getValor(claveParametro, lineaCredito) {
      const url = `${API_URL}api/servicioParametros/byclave`;
      const headers = { token: localStorage.getItem('token') };
      const queryTasaRef = {
        method: 'POST',
        url,
        data: {
          lineaCredito_id: lineaCredito,
          clave: 'tasa_referencia',
        },
        headers,
      };
      const { data: tasaRef } = await axios(queryTasaRef);
      if (tasaRef.valor === '1' && tasaRef.status === 'success') {
        this.tasa = await this.getTasaVariable();
        this.tasaMostrar = this.tasa;
      }
      if (tasaRef.valor !== '1') {
        const queryTasa = {
          method: 'POST',
          url,
          data: {
            lineaCredito_id: lineaCredito,
            clave: claveParametro,
          },
          headers,
        };
        const { data: tasa } = await axios(queryTasa);
        if (tasa.status === 'success') this.tasa = tasa.valor;
      }
    },
    formatoMoneda(valor) {
      const val = (valor / 1).toFixed(2).replace('.', ',');
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    },
    formatoPorcentaje(valor) {
      const numTemp = parseFloat(valor).toFixed(2);
      return Number(numTemp.toString().replace('.', ''));
    },
    async cargadorMaestro() {
      this.loadingGral = true;
      const url = `${API_URL}api/operatoria`;
      const header = {
        headers: {
          'Content-Type': 'application/json',
          token: this.token,
        },
      };
      axios
        .post(
          url,
          {
            operatoria_id: this.$route.params.servicioid
              ? this.$route.params.servicioid
              : this.$route.params.operatoriaId,
            cliente_id: localStorage.getItem('cliente_id'),
          },
          header,
        )
        .then((r) => {
          const { parametrosCliente, requisitos, operatoria, parametrosOperatoria, unidadgestion } = r.data.result;
          this.listaParametrosOperatoriaCliente =
            parametrosCliente.length !== 0 ? parametrosCliente.map((el) => ({ ...el, model: '' })) : parametrosCliente;
          if (requisitos.length > 0) {
            this.requisitosArray = requisitos;

            this.requisitosArray.sort((a, b) => {
              const nombreA = a.nombre.toLowerCase();
              const nombreB = b.nombre.toLowerCase();

              // Compare the names
              if (nombreA < nombreB) {
                return -1;
              }
              if (nombreA > nombreB) {
                return 1;
              }
              return 0;
            });

            this.requisitosListos = true;
          } else {
            this.requisitosListos = false;
          }
          if (operatoria.tipo_servicio_id === 13) {
            this.datosParametros = parametrosCliente;
            const array = [
              'monto_maximo',
              'monto_minimo',
              'plazo_credito_maximo',
              'plazo_credito_minimo',
              'periodo_gracia_maximo',
              'tasa',
              'factor_bonificacion',
            ]; // Parametros necesarios para ver
            const contador = array.reduce(
              (acc, clave) => {
                const validar = parametrosOperatoria.some((param) => param.tipo_parametro_id?.clave === clave);
                if (validar) acc[0] += 1;
                return acc;
              },
              [0],
            );
            this.parametros = array.length === contador[0];
          }
          this.servParametros = parametrosOperatoria.map((f) => ({
            id: f.tipo_parametro_id?.id,
            clave: f.tipo_parametro_id?.clave,
            lineasCredito: f.lineaCredito_id,
            valor: f.valor,
          }));
          this.validarCulturalyTurismo();
          // Operatoria
          this.tipoCredito = 2;
          this.requisitos = false;
          this.lineaSeleccionada = operatoria.id;
          this.tipoServicioId = operatoria.tipo_servicio_id;
          this.tipoServicioNombre = operatoria.tipo_servicio_nombre;
          this.nombre = operatoria.nombre;
          this.descripcionLinea = operatoria.descripcion;
          this.imagenLinea = `${API_URL}/lineas/${operatoria.imagenURL}`;
          this.sectorProductivoLinea = operatoria.sectorProductivo;
          this.LineaSeleccionadaOk = true;
          this.mostrarInformacion = 1;
          this.basesMostrar = operatoria.bases;
          this.operatoria = operatoria;
          // Unidad Gestion
          // this.nombre = response.data.fideicomiso.nombre;
          // this.descripcion = response.data.fideicomiso.descripcion;
          // this.imagen = response.data.fideicomiso.imagenURL;
          this.sectorProductivo = unidadgestion.sectorProductivo;

          this.loadingGral = false;
        })
        .catch((error) => {
          this.requisitosListos = false;
          console.log(error);
        });
    },
  },

  created() {
    this.cargadorMaestro();
    this.getValor('tasa', this.$route.params.servicioid);
    if (this.$route.params.mostrarWizard === '1') {
      this.tipoCredito = this.$route.params.tipoCredito;
      this.lineaSeleccionada = this.$route.params.id;
      this.mostrarWizard();
    }
  },
};
</script>
<style>
.fade-enter-active {
  /* Visualizado Div*/
  transition: all 0.4s ease;
}
.fade-leave-active {
  /* Ocultado Div*/
  transition: all 0.3s ease-out;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
  /*transform: translateY(-50px);*/
}
</style>
