<template>
    <div>
      <v-row>
        <v-col>
          <v-alert
            v-if="topeReintegroFlag"
            border="left"
            color="warning"
            dense
            outlined
            text
            type="warning"
          >Tope de Reintegro no configurado</v-alert>
        </v-col>
      </v-row>
      <v-row>
      <v-col v-for="item in listaParametros"
        cols="12"
        :key="item.id">
            <v-text-field v-if="item.clave !== 'liquidacion_aprobada'
            && item.clave !== 'monto_desembolso'
            && item.clave !== 'dni_representante'
            && item.clave !== 'tipo_de_documento'
            && item.clave !== 'condicion_iva'
            && item.clave !== 'localidad'
            && item.clave !== 'fisica_juridica'
            && item.clave !== 'cbu'
            && item.clave !== 'qe'"
              v-model="item.model"
              :label="item.nombre"
              :rules="regla"
              :type='validarTipo(item.options)'
              :disabled='checkbox'
              required
            ></v-text-field>
            <v-autocomplete v-if="item.clave === 'tipo_de_documento'"
              :disabled='checkbox'
              v-model="item.model"
              :items="tipoDeDocumentos"
              :label='item.nombre'
              hide-no-data
              hide-selected
            ></v-autocomplete>
            <v-autocomplete v-if="item.clave === 'localidad'"
              :disabled='checkbox'
              v-model="item.model"
              :items="localidades"
              :label='item.nombre'
              hide-no-data
              hide-selected
            ></v-autocomplete>
            <v-autocomplete v-if="item.clave === 'condicion_iva'"
              :disabled='checkbox'
              v-model="item.model"
              :items="codigoIva"
              :label='item.nombre'
              hide-no-data
              hide-selected
            ></v-autocomplete>
            <v-select v-if="item.clave === 'fisica_juridica'"
              :disabled='checkbox'
              v-model="item.model"
              :items="[{text:'Jurídica', value: 0}, {text: 'Física', value: 1}]"
              :label='item.nombre'
            ></v-select>
             <v-text-field v-if="item.clave == 'liquidacion_aprobada'"
              :disabled='checkbox'
              v-model="item.model"
              :label="item.nombre"
              :rules="rulesTopeReintegro"
              @keyup="validarMax(item.clave, item.nombre)"
              :max="topeReintegro"
              type='number'
              required
            ></v-text-field>
          <v-text-field v-if="item.clave == 'monto_desembolso'"
              :disabled='checkbox'
              v-model="item.model"
              :label="item.nombre"
              :max="topeReintegro"
              value="0"
              @keyup="validarMaxMonto(item.clave, item.nombre)"
              :rules="rulesTopeReintegro"
              hide-details
              type='number'
              required
            ></v-text-field>
            <v-text-field v-if="item.clave == 'dni_representante'"
              :disabled='checkbox'
              v-model="item.model"
              :counter="11"
              @keypress="valCUITCUIL(item.model)"
              v-on:blur="valCUITCUILMin(item.model)"
              ref="cuilcuitben"
              clearable
              :label="item.nombre"
              type='number'
              hint="Ingrese el CUIL/CUIT sin guiones o espacios."
              required
            ></v-text-field>
            <v-text-field v-if="item.clave == 'cbu'"
              :disabled='checkbox'
              v-model="item.model"
              ref="cbutextfield"
              :label="item.nombre"
              :max="topeReintegro"
              :success="cbuSuccess"
              :loading="loadingCBU"
              :error="cbuError"
              :messages="cbuMsg"
              @change="validarCBU(item.model)"
              :hide-details="false"
              type='text'
              required
            ></v-text-field>
        </v-col>
        <v-col>
            <v-card v-if='listaParametros.length < 1'>
                <v-card-title>Datos Requisitos: </v-card-title>
                <v-card-text>No se encuentra habilitado por falta de parámetros
                </v-card-text>
            </v-card>
            <div>
                <v-checkbox v-if="listaParametros.length !== 0
                && !datosConfirmados"
                v-model='checkbox'
                @click="validacionesCheck"
                :rules="[v => !!v || 'Debe aceptar para continuar!']"
                label="Acepto los valores"
                required
                ></v-checkbox>
                <v-row>
                  <v-col>
                    <v-btn v-if="listaParametros.length !== 0
                    && !datosConfirmados"
                    color="success"
                    class="mr-4"
                    @click="setDatosParametros"
                    :disabled ="listaParametros.some((el) => el.model === '')
                    || !checkbox || !cuitValido || nroTicketValido"
                    >
                    Confirmar Valores
                    </v-btn>
                    <v-alert
                      v-if="datosConfirmados"
                      border="left"
                      color="green"
                      dense
                      outlined
                      text
                      type="success"
                    >Los parametros han sido confirmados</v-alert>
                  </v-col>
                </v-row>
            </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="8">
          <v-alert v-if="success"
            border="bottom"
            color="success"
            dark
          >
            {{ successMensaje }}
          </v-alert>
          <v-alert v-if="error"
            border="bottom"
            color="red"
            dark
          >
            <b>Ocurrió un error al guardar el Parametro de la operatoria.</b> {{ errorMensaje }}
          </v-alert>
        </v-col>
      </v-row>
    </div>
</template>
<script>
import { API_URL } from '@/common/config';
import axios from 'axios';

export default {
  props: ['listaParametrosOperatoriaCliente', 'parametrosOperatoria', 'lineaSeleccionada'],
  data: () => ({
    loadingCBU: false,
    cbuSuccess: false,
    cbuError: false,
    cbuMsg: '',
    topeReintegroFlag: false,
    topeReintegro: 0,
    prueba: '',
    datosParametros: [],
    datosConfirmados: false,
    valid: true,
    cuitValido: true,
    nroTicketValido: true,
    validaSolicitante: true,
    localidades: [],
    tipoDeDocumentos: [],
    codigoIva: [],
    token: localStorage.getItem('token'),
    rules: {
      required: (value) => !!value || 'Campo requerido',
      number: (value) => /[0-9]*[.][0-9]+/.test(value) || 'El valor ingresado debe ser numérico.-',
    },
    rulesTopeReintegro: [],
    checkbox: false,
    success: false,
    error: false,
    successMensaje: '',
    errorMensaje: '',
    listaParametros: undefined,
  }),
  methods: {
    valCUITCUIL(v) {
      if (v.length > 10) {
        this.$swal.fire('CUIL/CUIT del Beneficiario', 'Debe ingresar solo CUIT/CUIL sin guiones y/o espacios', 'error');
        const dni = this.listaParametros.filter((p) => p.clave === 'dni_representante')[0];
        console.log(dni);
        dni.model = '';
      }
    },
    valCUITCUILMin(v) {
      if (v.length < 10) {
        this.$swal.fire('CUIL/CUIT del Beneficiario', 'Debe ingresar solo CUIT/CUIL sin guiones y/o espacios', 'error');
        const dni = this.listaParametros.filter((p) => p.clave === 'dni_representante')[0];
        dni.model = '';
      }
    },
    validarTipo(t) {
      let r = '';
      if (t === 'int') {
        r = 'number';
      } else {
        r = 'string';
      }
      return r;
    },
    setDatosParametros() {
      this.datosParametros = this.listaParametros
        .map((el) => ({ id: el.id, nombre: el.clave, value: el.model }));
      this.$emit('enviarParametros', this.datosParametros);
      this.datosConfirmados = true;
    },
    validarMax(clave, nombre) {
      const v = this.listaParametros.filter((p) => p.clave === clave)[0].model;
      if (v > parseFloat(this.topeReintegro)) {
        this.$swal.fire('Alerta', `El ${nombre} no puede ser mayor al Tope de Reintegro ($ ${this.topeReintegro})`, 'error');
        this.listaParametros.filter((p) => p.clave === clave)[0].model = 0;
      }
    },
    validarMaxMonto(clave, nombre) {
      const v = this.listaParametros.filter((p) => p.clave === clave)[0].model;
      if (v > parseFloat(this.topeReintegro * 0.4)) {
        this.$swal.fire('Alerta', `El ${nombre} no puede ser mayor al 40% de la liquidacion aprobada ($ ${this.topeReintegro * 0.4})`, 'error');
        this.listaParametros.filter((p) => p.clave === clave)[0].model = 0;
      }
    },
    getLocalidad() {
      const url = `${API_URL}api/trilay/localidad/43`;
      const header = { token: this.token };
      axios
        .get(url, {
          headers: header,
        })
        .then((response) => {
          this.localidades = response.data.result[0]
            .map((el) => ({ text: el.nombre, value: el.codlocalidad }));
        });
    },
    getTipoDocumento() {
      const url = `${API_URL}api/trilay/tipodoc`;
      const header = { token: this.token };
      axios
        .get(url, {
          headers: header,
        })
        .then((response) => {
          this.tipoDeDocumentos = response.data.result[0]
            .map((el) => ({ text: el.nombre, value: el.codtipodoc }));
        });
    },
    getCodigoIva() {
      const url = `${API_URL}api/trilay/codigoiva`;
      const header = { token: this.token };
      axios
        .get(url, {
          headers: header,
        })
        .then((response) => {
          this.codigoIva = response.data.result[0]
            .map((el) => ({ text: el.nombre, value: el.codiva }));
        });
    },
    validarCBU(clavebancariaunica) {
      this.loadingCBU = true;
      const url = `${API_URL}api/validar/cbu`;
      const header = {
        headers: {
          'Content-Type': 'application/json',
          token: localStorage.getItem('token'),
        },
      };
      const data = {
        cbu: clavebancariaunica,
      };
      axios.post(url, data, header).then((result) => {
        let msg = '';
        if (result.data.valid) {
          msg = 'CBU válido';
          this.cbuSuccess = true;
          this.cbuError = false;
        } else {
          msg = 'CBU no válido';
          this.cbuError = true;
          this.cbuSuccess = false;
        }
        this.loadingCBU = false;
        this.cbuMsg = msg;
      });
    },
    validacionesCheck() {
      console.log('Chequeando Numero de ticket y Cuil/Cuit');
      this.validarCuitDni();
      this.validarNroTicket();
    },
    validarCuitDni() {
      const dnicuit = this.listaParametros
        .find((el) => el.clave === 'dni_representante')?.model;
      const url = `${API_URL}api/mendozaactiva/existe`;
      const header = {
        headers: {
          'Content-Type': 'application/json',
          token: localStorage.getItem('token'),
        },
      };
      const data = {
        dnicuit,
        lineaCredito_id: this.lineaSeleccionada,
      };
      axios.post(url, data, header).then((result) => {
        this.cuitValido = !result.data.existe;
      });
    },
    validarNroTicket() {
      const valor = this.listaParametros
        .find((el) => el.clave === 'numero_ticket')?.model;
      const url = `${API_URL}api/clienteParametros/validar/numero_ticket`;
      const header = {
        headers: {
          'Content-Type': 'application/json',
          token: localStorage.getItem('token'),
        },
      };
      const data = {
        valor,
      };
      axios.post(url, data, header).then((result) => {
        const mensaje = result.data.message;
        if (result.data.existe) {
          this.$swal.fire('Alerta', mensaje, 'error');
        }
        this.nroTicketValido = result.data.existe;
      });
    },
    validarSolicitanteOperatoria() {
      const valor = this.listaParametros
        .find((el) => el.clave === 'dni_solicitante')?.model;
      const url = `${API_URL}api/clienteParametros/validar/numero_ticket`;
      const header = {
        headers: {
          'Content-Type': 'application/json',
          token: localStorage.getItem('token'),
        },
      };
      const data = {
        dni: valor,
        operatoria_id: 14,
      };
      axios.post(url, data, header).then((result) => {
        const mensaje = result.data.message;
        if (result.data.existe) {
          this.$swal.fire('Alerta', mensaje, 'error');
        }
        this.validaSolicitante = result.data.existe;
      });
    },
  },
  watch: {
    cuitValido(n) {
      if (!n) {
        this.$swal.fire('Alerta', `Ya existe un beneficiario cargado previamente con el CUIT/CUIL ${this.dnicuit}`, 'error');
      }
    },
    nroTicketValido(n) {
      const valor = this.listaParametros
        .find((el) => el.clave === 'numero_ticket')?.model;
      if (n) {
        this.$swal.fire('Alerta', `Ya existe un legajo cargado previamente con este Número de Ticket ${valor}`, 'error');
      }
    },
  },
  computed: {
    regla() {
      return [
        (v) => !!v || 'Falta completar campo',
      ];
    },
  },
  created() {
    this.getLocalidad();
    this.getTipoDocumento();
    this.getCodigoIva();
    this.listaParametros = this.listaParametrosOperatoriaCliente;
    let lista = this.listaParametros.map((el) => ({ ...el, model: '' }));
    lista = lista.filter((val) => val.clave !== 'qe');
    this.listaParametros = lista;
    const topereintegro = this.parametrosOperatoria.filter((p) => p.clave === 'tope_reintegro')[0];
    if (typeof topereintegro !== 'undefined') {
      this.topeReintegro = topereintegro.valor;
      this.rulesTopeReintegro = [
        (v) => ((v <= topereintegro.valor)) || 'El valor no debe ser mayor al tope de reintegro',
      ];
    } else {
      this.topeReintegroFlag = true;
    }
    this.validarSolicitanteOperatoria();
  },
};
</script>
<style>
.swal2-title, .swal2-content, .swal2-styled {
  font-family: Roboto,sans-serif !important;
}
</style>
