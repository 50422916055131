<template>
  <v-dialog v-model="dialog" persistent max-width="900px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="primary" dark v-bind="attrs" v-on="on" block> Simular </v-btn>
    </template>
    <v-card>
      <v-card-title>
        <span class="headline">Previsualización del credito</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="4">
              <v-text-field
                label="Monto financiado"
                v-model="montoFinanciado"
                :rules="montoRules"
                type="number"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="3">
              <v-text-field
                label="Período de gracia"
                v-model="periodoGracia"
                :rules="periodoRules"
                type="number"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="3">
              <v-text-field
                label="Plazo de crédito"
                v-model="cuotas"
                :rules="plazoRules"
                type="number"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="2">
              <v-text-field label="Tasa" :value="tasa" type="number" readonly></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-select
                label="Pagos"
                v-model="pagos"
                :items="[
                  { value: 1, text: 'Mensual' },
                  { value: 2, text: 'Bimestral' },
                  { value: 3, text: 'Trimestral' },
                  { value: 6, text: 'Semestral' },
                  { value: 12, text: 'Anual' },
                ]"
              >
              </v-select>
            </v-col>
            <v-col cols="6">
              <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                :return-value.sync="fecha"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="formatDate"
                    label="Fecha de inicio"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="fecha" no-title scrollable>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="menu = false"> Cancelar </v-btn>
                  <v-btn text color="primary" @click="$refs.menu.save(fecha)"> Aceptar </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn v-if="datos" color="black" text @click="exportPDF('0')"> Imprimir </v-btn>
        <v-btn v-if="datos" color="blue" text @click="exportPDF('1')"> Descargar </v-btn>
        <v-btn color="green darken-1" text @click="calcular"> Calcular </v-btn>
        <v-btn color="red darken-1" text @click="salir"> Salir </v-btn>
      </v-card-actions>
      <v-row v-if="showDatos">
        <v-col lg="12" sm="12" md="12" xl="12" align="center">
          <v-icon color="black darken-2"> mdi-spin mdi-loading </v-icon>
        </v-col>
      </v-row>
      <v-card-text>
        <v-simple-table v-if="showTable" id="content">
          <template v-slot:default>
            <thead id="my-tables">
              <tr>
                <th class="text-left">Fecha</th>
                <th class="text-left">Saldo Inicial</th>
                <th class="text-left">Amortización</th>
                <th class="text-left">Intereses</th>
                <th class="text-left">Cuota</th>
                <th class="text-left">Saldo Final</th>
                <th class="text-left">IVA Intereses</th>
                <th class="text-left">Cuota Total</th>
              </tr>
            </thead>
            <tbody id="my-table">
              <tr v-for="(item, d) in datos" :key="item.Saldo_Final">
                <td>{{ d }}</td>
                <td>{{ parseFloat(item.Saldo_Inicial).toFixed(2) }}</td>
                <td>{{ parseFloat(item.Amortizacion).toFixed(2) }}</td>
                <td>{{ parseFloat(item.Intereses).toFixed(2) }}</td>
                <td>{{ parseFloat(item.Cuota).toFixed(2) }}</td>
                <td>{{ parseFloat(item.Saldo_Final).toFixed(2) }}</td>
                <td>{{ parseFloat(item.IVA_Intereses).toFixed(2) }}</td>
                <td>{{ parseFloat(item.Cuota_Total).toFixed(2) }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import { APP_SIMULADOR_URL } from '@/common/config';
import axios from 'axios';
import moment from 'moment';
import JsPDF from 'jspdf';
import logo from '../common/logo';
import 'jspdf-autotable';

export default {
  data: () => ({
    dialog: false,
    loaded: false,
    montoFinanciado: '',
    datos: '',
    nolaborablesArray: [],
    cuotas: '',
    fecha: new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
    menu: false,
    pagos: '',
    periodoGracia: '',
    fb: 1,
    tasa: null,
    showTable: false,
    showDatos: false,
  }),
  props: {
    params: {
      type: Array,
    },
    nombre: {
      type: String,
    },
  },
  computed: {
    formatDate() {
      return this.fecha ? moment(this.fecha).format('DD-MM-YYYY') : '';
    },
    periodoRules() {
      return [
        (v) => !!v || 'Falta el periodo',
        (v) =>
          (v >= 0 && v <= parseInt(this.params.find((e) => e.clave === 'periodo_gracia_maximo').valor, 10)) ||
          `El período debe gracia máximo ${this.params.find((e) => e.clave === 'periodo_gracia_maximo').valor}`,
      ];
    },
    montoRules() {
      return [
        (v) => !!v || 'Falta monto de la operación',
        (v) =>
          (v >= parseInt(this.params.find((e) => e.clave === 'monto_minimo').valor, 10) &&
            v <= parseInt(this.params.find((e) => e.clave === 'monto_maximo').valor, 10)) ||
          `El monto debe estar entre ${this.params.find((e) => e.clave === 'monto_minimo').valor} y ${this.params.find((e) => e.clave === 'monto_maximo').valor}`,
      ];
    },
    plazoRules() {
      return [
        (v) => !!v || 'Falta valor plazo',
        (v) =>
          (v >= parseInt(this.params.find((e) => e.clave === 'plazo_credito_minimo').valor, 10) &&
            v <= parseInt(this.params.find((e) => e.clave === 'plazo_credito_maximo').valor, 10)) ||
          `El plazo debe estar entre ${this.params.find((e) => e.clave === 'plazo_credito_minimo').valor} y ${this.params.find((e) => e.clave === 'plazo_credito_maximo').valor}`,
      ];
    },
  },
  watch: {
    params() {
      this.tasa = parseInt(this.params.find((e) => e.clave === 'tasa').valor, 10);
    },
  },
  methods: {
    exportPDF(tipo) {
      const doc = new JsPDF();
      const columns = [
        { title: 'Nro', dataKey: 'Nro' },
        { title: 'Fecha', dataKey: 'Fecha' },
        { title: 'Saldo Inicial', dataKey: 'Saldo_Inicial' },
        { title: 'Amortización', dataKey: 'Amortizacion' },
        { title: 'Intereses', dataKey: 'Intereses' },
        { title: 'Cuota', dataKey: 'Cuota' },
        { title: 'Saldo Final', dataKey: 'Saldo_Final' },
        { title: 'IVA Intereses', dataKey: 'IVA_Intereses' },
        { title: 'Cuota Total', dataKey: 'Cuota_Total' },
      ];
      const data = JSON.parse(JSON.stringify(this.datos));
      const result = [];
      let i = 0;
      Object.entries(data).forEach((key) => {
        result.push({
          Nro: (i += 1),
          Amortizacion: parseFloat(key[1].Amortizacion).toFixed(2),
          Cuota: parseFloat(key[1].Cuota).toFixed(2),
          Cuota_Total: parseFloat(key[1].Cuota_Total).toFixed(2),
          Fecha: key[0],
          IVA_Intereses: parseFloat(key[1].IVA_Intereses).toFixed(2),
          Intereses: parseFloat(key[1].Intereses).toFixed(2),
          Saldo_Final: parseFloat(key[1].Saldo_Final).toFixed(2),
          Saldo_Inicial: parseFloat(key[1].Saldo_Inicial).toFixed(2),
        });
      });
      const apellido = `${localStorage.getItem('usuarioApellido')} ${localStorage.getItem('usuarioNombre')}`;
      // img.src = 'https://mendozafiduciaria.com/wp-content/uploads/2020/09/Mendoza-Fiduciaria-logo-color-fondo-transparente.png';
      const imgData = logo;
      doc.addImage(imgData, 'PNG', 10, 5, 48, 20);
      doc.line(15, 23, 200, 23, 'FD');
      doc.setFont('helvetica', 'bold');
      doc.setFontSize(18);
      doc.text('Cuadro de Marcha', 15, 30);
      doc.text(this.formatDate, 160, 30);
      doc.text(`Usuario: ${apellido}`, 15, 37);
      doc.line(15, 39, 200, 39, 'FD');
      doc.setFontSize(15);
      doc.text(`Monto: $ ${this.montoFinanciado}`, 15, 45);
      doc.text(`Plazo: ${this.cuotas}`, 70, 45);
      doc.text(`Tasa: ${this.tasa}%`, 105, 45);
      doc.text(`Período: ${this.pagos}`, 145, 50);
      doc.text(`Factor Bonif: ${this.fb}`, 15, 52);
      doc.text(`Período de Gracia: ${this.periodoGracia}`, 70, 52);
      const splitTitle = doc.splitTextToSize(this.nombre, 180);
      doc.text(splitTitle, 15, 70);
      doc.autoTable(columns, result, {
        startY: 85,
        margin: { horizontal: 7 },
        styles: { cellWidth: 'wrap', halign: 'center' },
        columnStyles: { text: { cellWidth: 'auto' } },
      });
      if (tipo === '0') {
        window.open(doc.output('bloburl'), '_blank');
      } else {
        doc.save(`Cuadro de Marcha ${apellido}.pdf`);
      }
    },
    async nolaborables(year) {
      const nl = await axios.get(`http://nolaborables.com.ar/api/v2/feriados/${year}`);
      const nolab = nl.data.map((f) => `${year}-${f.mes}-${f.dia}`);
      return nolab;
    },
    calcular() {
      this.showDatos = true;
      const thisaux = this;
      setTimeout(() => {
        console.log('dentro del settimeout');
        thisaux.calcularCredito();
      }, 1000);
    },
    async calcularCredito() {
      this.tasa = this.params.find((e) => e.clave === 'tasa').valor;
      this.fb = this.params.find((e) => e.clave === 'factor_bonificacion').valor;
      await axios
        .get(
          `${APP_SIMULADOR_URL}${this.montoFinanciado}/${this.cuotas}/${this.pagos}/${this.tasa}/${this.formatDate}/${this.periodoGracia}/${this.fb}`,
        )
        .then((response) => {
          this.datos = response.data;
          this.showDatos = false;
          this.showTable = true;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async getTasa() {
      const data = {
        url: 'https://www.bna.com.ar/Home/InformacionAlUsuarioFinanciero',
        caracter: 8,
      };
      const json = JSON.stringify(data);
      const header = {
        headers: {
          'Content-Type': 'application/json',
        },
      };
      await axios
        .post(`${APP_SIMULADOR_URL}tnav`, json, header)
        .then((response) => {
          this.tasa = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    salir() {
      this.dialog = false;
      this.showTable = false;
    },
  },
};
</script>
